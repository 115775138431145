import {auth, firestore} from "../firebase.config";
import FirestoreProvider from "../Database/firebase.firestore";

export default class FirebaseAuthProvider {

  /**
   * Create a User Document
   * @param {firebase.default.User} userAuth 
   * @param  {...any} additionalData 
   */
  createUserProfileDocument = async (userAuth: any, additionalData: any) => {
    if (!userAuth) return;
    const userRef = firestore.doc(`users/${userAuth.uid}`);
    const privateUserRef = firestore.collection('users').doc(userAuth.uid).collection('private').doc('contact');
    await privateUserRef.set({email: userAuth.email});
    const snapShot = await userRef.get();
    if (!snapShot.exists) {
      const {displayName} = userAuth;
      const createdAt = Date.now();

      await userRef.set({
        displayName,
        recieveUpdates: false,
        is_staff: false,
        is_active: true,
        is_onFrontPage: false,
        rank: "Default",
        dateOfBirth: null,
        uuidArray: [],
        uuid: userAuth.uid,
        timestamp: createdAt,
        ...additionalData,
      }).catch((e) => {
        return e;
      });
    }
    return userRef;
  }

  /**
  * Sign in with Google Account
  * @param {firebase.default.auth.AuthProvider} provider
  */
  async signInWithProvider(provider: any) {
    return new Promise((resolve, reject) => {
      provider.setCustomParameters({promt: "select_account"});
      auth.signInWithPopup(provider).then((data) => {
        resolve({error: false, message: "SUCCESS", data: data});
      }).catch((e) => {
        reject({error: true, message: "SIGN_IN_FAILED", data: e});
      });
    });
  }

  /**
   * Sign in with Email and Password
   * @param {String} email 
   * @param {String} password 
   */
  async signInWithEmail(email: string, password: string) {
    return new Promise((resolve, reject) => {
      auth.signInWithEmailAndPassword(email, password).then((data) => {
        resolve({error: false, message: "LOGIN_SUCCESS", data: data});
      }).catch((e) => {
        reject({error: true, message: "LOGIN_ERROR", data: e});
      });
    });
  }

  /**
   * Create User with Email and Password
   * @param {String} email 
   * @param {String} password 
   */
  async signUpWithEmail(email: string, password: string) {
    return new Promise((resolve, reject) => {
      auth.createUserWithEmailAndPassword(email, password).then((data) => {
        resolve({error: false, message: "LOGIN_SUCCESS", data: data});
      }).catch((e) => {
        reject({error: true, message: "LOGIN_ERROR", data: e});
      });
    });
  }

  /**
   * Sign Out the User
   */
  async signOut() {
    return new Promise((resolve, reject) => {
      auth.signOut().then((data) => {
        resolve({error: false, message: "LOGOUT_SUCCESS", data: data});
      }).catch((e) => {
        reject({error: true, message: "LOGOUT_FAILED", data: e});
      });
    });
  }

  async linkMinecraftAccount(otp: string) {
    const fireStoreProvider = new FirestoreProvider();
    return new Promise(async (resolve, reject) => {

      // Get Alias From OTP if OTP Verifies
      const otpData = await fireStoreProvider.checkOTP(otp);
      if (otpData.error) {
        reject("Alias Not Found");
      }

      // Check if that Alias is Already Registered
      const alreadyRegistered = await new FirestoreProvider().uuidCheck(otpData.uuid);
      if (alreadyRegistered) {
        reject("Already Registered on Website");
        return;
      }

      const timestamp = Date.now();
      firestore.collection(`/users/${auth.currentUser?.uid}/alias`).add({
        name: otpData.alias,
        uuid: otpData.uuid,
        lastLoginTime: timestamp,
        timestamp: timestamp,
      });

      // Get All the UUID and Insert UUID in the array.
      firestore.doc(`/users/${auth?.currentUser?.uid}`).get().then((document) => {
        const uuidArray = document.get("uuidArray");
        uuidArray.push(otpData.uuid);

        // Update Alias Array
        firestore.doc(`/users/${auth?.currentUser?.uid}`).update({
          uuidArray: uuidArray,
        });

        otpData.documentRef.ref.delete();
      });
    });
  }

  async registerNewAlias(alias: string, password: string, cnfPassword: string): Promise<any> {
    return new Promise(async (resolve, reject) => {

      if (alias === "" || password === "" || cnfPassword === "") {
        reject({error: true, message: "Required Fields Missing"});
        return;
      }

      if (password !== cnfPassword) {
        reject({error: true, message: "Password Mismatch"});
        return;
      }

      const alreadyRegistered = await new FirestoreProvider().uuidCheck(alias);
      if (alreadyRegistered) {
        reject({error: true, message: "Already Registered on Website"});
        return;
      }

      const isPremium = await new FirestoreProvider().premiumAliasCheck(alias);
      if (isPremium) {
        reject({error: true, message: "Is a Premium Account"});
        return;
      }

      const isAlreadyRegisteredOnServer = await new FirestoreProvider().accountRegisteredOnServerCheck(alias);
      if (isAlreadyRegisteredOnServer) {
        reject({error: true, message: "Already Registered Username on Server"});
        return;
      }

      new FirestoreProvider().registerAccountOnServer(alias, password).then((data) => {
        console.log(data);
      });

    });

  }
}
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import {Provider} from "react-redux";
import store from './services/state/store';
import {ThemeProvider} from '@material-ui/styles';
import {createTheme, responsiveFontSizes} from '@material-ui/core';
import ServerStatusController from './controller/server.state.controller';

let theme = createTheme({
  palette: {
    type: 'dark',
    // primary: {
    //   light: "#0186DF",
    //   main: "#016FB9",
    //   dark: "#0162A2",
    // },
    // secondary: {
    //   light: "#FC4A9D",
    //   main: "#D90368",
    //   dark: "#DD0369",
    // },
    // error: {
    //   light: "#EA9571",
    //   main: "#E26E3C",
    //   dark: "#D55620",
    // },
    // warning: {
    //   light: "#0186DF",
    //   main: "#016FB9",
    //   dark: "#0162A2",
    // },
    // info: {
    //   light: "#0186DF",
    //   main: "#016FB9",
    //   dark: "#0162A2",
    // },
    // success: {
    //   light: "#9FD1B3",
    //   main: "#7FC29B",
    //   dark: "#5AAF7D",
    // }
  },
  typography: {
    fontFamily: [
      'Montserrat',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  }
});

theme = responsiveFontSizes(theme);

ReactDOM.render(
  // <React.StrictMode>
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <ServerStatusController />
    </Provider>
  </ThemeProvider>,
  // </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from "react";
import {Card} from "react-bootstrap";
import {Button, TextField, Typography} from "@material-ui/core";
import FirebaseAuthProvider from "../../../services/Auth/firebase.auth";

export default function AccountLinkCard() {

  const [otp, setOTP] = React.useState("");
  const [processing, updateProcess] = React.useState(false);

  function handleSubmit(event: any) {
    event.preventDefault();
    updateProcess(true);
    new FirebaseAuthProvider().linkMinecraftAccount(otp).then((data) => {
      console.log(data);
      updateProcess(false);
    });
  }

  return (
    <Card className="bg-dark px-5 py-4">
      <Typography variant="h5" > Link your Account <Typography className="text-muted" variant="body2"> For Legacy Accounts </Typography> </Typography>
      <form className="pt-4" >
        <TextField
          type="number"
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="otp"
          label="Linking Token"
          name="otp"
          autoComplete='off'
          onChange={(event) => setOTP(event.target.value)}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className="my-2"
          onClick={handleSubmit}
        > Verify Token </Button>
        {/* <Typography variant="body2" className="text-muted" >
          This is only for those who have legacy account before we started registration from website.
          If you registered your Server account from this Website you dont need to do this.
        </Typography> */}
      </form>
    </Card>
  );
}

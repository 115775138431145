import React from 'react';

export function Discord() {
  window.open("https://discord.gg/Xatf3UPRXu");
  return <div />
}

export function Instagram() {
  window.open("https://www.instagram.com/dpcraftnetwork/");
  return <div />
}

export function Youtube() {
  window.open("https://www.youtube.com/channel/UCvgEuJ2952U-QztFPgBJqjA");
  return <div />
}

export function Facebook() {
  window.location.href = "https://discord.gg/Xatf3UPRXu";
  window.open("https://store.dpcraftnetwork.com");
  return <div />
}

export function Store() {
  window.open("https://store.dpcraftnetwork.com");
  window.history.go(-1);
  return <div />
}
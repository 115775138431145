import React from 'react';
import './footer.style.scss';
import {Row, Col} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faMugHot} from '@fortawesome/free-solid-svg-icons'
import FooterData from './footer';

export default function Footer() {
    return (
        <div id={FooterData.id} >
            <FooterBar socialLinks={FooterData.socialLinks} />
        </div>
    );
}

function FooterBar({socialLinks}) {
    return (
        <div className="container-fluid footerBar bg-dark text-white text-center p-5">
            <div>
                <Row className="text-center justify-content-center" noGutters={true}>
                    {socialLinks.map(({...Data}, index) => {
                        return <FooterIcon key={index + "_key"} {...Data} />
                    })}
                </Row>
            </div>
            <h5 className="copyright mt-5" >Copyright ©{new Date().getFullYear()} All rights reserved. <FontAwesomeIcon className="ml-2" icon={faMugHot} /></h5>
        </div>
    );
}

function FooterIcon({href, alt, img}) {
    return (
        <>
            <Col xs="6" sm="2" className="mt-2" >
                <div className="footerIcon">
                    <a href={href} target="_blank" rel="noopener noreferrer">
                        <img alt={alt} src={img} target="_blank" />
                    </a>
                </div>
            </Col>
        </>
    );
}

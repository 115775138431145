import gmail from '../../assets/gmail.png';
import youtube from '../../assets/youtube.png';
import discord from '../../assets/discord.png';
import instagram from '../../assets/instagram.png';

const FooterData = {
  socialLinks: [
    {
      alt: "Instagram",
      img: instagram,
      href: "https://www.instagram.com/dpcraftnetwork/",
    },
    {
      alt: "Mail",
      img: gmail,
      href: "mailto:dpcraftnetwork@gmail.com"
    },
    {
      alt: "Youtube",
      img: youtube,
      href: "https://www.youtube.com/channel/UCvgEuJ2952U-QztFPgBJqjA",
    },
    {
      alt: "Discord",
      img: discord,
      href: "https://discord.com/invite/Xatf3UPRXu"
    }
  ]
}

export default FooterData;
import App from '../pages/app/app';
import {BrowserRouter, Route} from "react-router-dom"
import {Discord, Instagram, Store, Youtube} from '../pages/Redirect/redirect';
// View Imports

import Blog from '../pages/blogs/updates';
import SignIn from '../pages/login/login';
import SignUp from '../pages/login/signup';
import Dashboard from '../pages/dashboard/dashboard';
import Support from '../pages/support/support.page';

export function PageRouter() {
  return (
    <BrowserRouter>
      <Route exact path="/" component={App} />
      <Route path="/updates" component={Blog} />
      <Route path="/staff" component={App} />
      <Route path="/login" component={SignIn} />
      <Route path="/register" component={SignUp} />
      <Route path="/support" component={Support} />
      <Route path="/dashboard" component={Dashboard} />

      {/* Redirect's */}
      <Route exact path="/discord" component={Discord} />
      <Route exact path="/insta" component={Instagram} />
      <Route exact path="/instagram" component={Instagram} />
      <Route exact path="/yt" component={Youtube} />
      <Route exact path="/youtube" component={Youtube} />
      <Route exact path="/store" component={Store} />
    </BrowserRouter>
  );
}

import firebase from '../firebase.config';
import FirebaseAuthProvider from '../Auth/firebase.auth';
import FirestoreProvider from '../Database/firebase.firestore';

export interface IActivePunishment {
  id: number,
  end: number,
  uuid: string,
  name: string,
  start: number,
  reason: string,
  operator: string,
  punishmentType: string,
}

export async function fetchBlogFromApi(dispatch: any, getState: any) {
  new FirestoreProvider().getCollectionSnapshot("/posts").then((response) => {
    dispatch({error: false, type: "GET_POST", payload: response.data.docs})
  });
}

export async function fetchStaffMembers(dispatch: any, getState: any) {
  new FirestoreProvider().getSearchCollectionSnapshot("/users", {field: "staff", operator: "==", value: true}).then((response) => {
    dispatch({error: false, type: "GET_STAFF", payload: response.data.docs})
  });
}

export async function fetchLandingPageData(dispatch: any, getState: any) {
  new FirestoreProvider().getSearchCollectionSnapshotWithLimit("/users", {field: "is_onFrontPage", operator: "==", value: true}, 10).then((response) => {
    dispatch({error: false, type: "GET_STAFF", payload: response.data.docs})
  });

  new FirestoreProvider().getCollectionSnapshotWithLimit("/posts", 3).then((response) => {
    dispatch({error: false, type: "GET_MAIN_POST", payload: response.data.docs})
  });
}

// Login Thunk Middleware
export async function login(dispatch: any, getState: any) {
  if (getState.type === "email") {
    new FirebaseAuthProvider().signInWithEmail(getState.data.email, getState.data.password).then((loginReply: any) => {
      dispatch({hasError: false, loggedIn: true, type: "LOGIN", payload: loginReply, error: null});
    }).catch((error: any) => {
      dispatch({hasError: true, loggedIn: false, type: "LOGIN", payload: null, error: error});
    });
  }

  if (getState.type === "googleLogin") {
    new FirebaseAuthProvider().signInWithProvider(new firebase.auth.GoogleAuthProvider()).then((loginReply: any) => {
      dispatch({hasError: false, loggedIn: true, type: "LOGIN", payload: loginReply, error: null})
    }).catch((error) => {
      dispatch({hasError: true, loggedIn: false, type: "LOGIN", payload: null, error: error})
    });
  }
}

export async function logout(dispatch: any, getState: any) {
  new FirebaseAuthProvider().signOut();
  dispatch({hasError: false, type: "LOGOUT"})
}

interface IRegisterState {
  email: string,
  password: string,
  firstName: string,
  lastName: string,
  cnfPassword: string,
  recieveUpdates: string,
}

export async function register(dispatch: any, getState: IRegisterState) {
  new FirebaseAuthProvider().signUpWithEmail(getState.email, getState.password).then((registerReply: any) => {
    dispatch({hasError: false, type: "REGISTER", payload: registerReply, error: null});

    new FirebaseAuthProvider()
      .createUserProfileDocument(registerReply.data.user,
        {displayName: getState.firstName + " " + getState.lastName, recieveUpdates: getState.recieveUpdates})
      .then((data) => {
        console.log(data);
      }).catch((e) => {
        console.log(e);
      });

    // firestore.collection("users").doc(registerReply.data.user.uid).update({
    //   displayName: getState.firstName + getState.lastName,
    //   recieveUpdates: getState.recieveUpdates,
    // }).then((data) => {
    //   console.log(data);

    // }).catch((error) => {
    //   console.log(error);
    // });

  }).catch((error) => {
    console.log(error);
    dispatch({hasError: true, type: "REGISTER", payload: null, error: error})
  });
}

export async function getWebsiteMainState(dispatch: any) {
  new FirestoreProvider().getDocumentSnapshot('/config/KAa5b4GP5i2qBUctkKNL').then((data) => {
    dispatch({type: 'MANINTANANCE', payload: data.data.get('maintanance')});
  });
}

export async function getUserAliasData(dispatch: any, state: any) {
  if (!state.loggedIn) {
    dispatch({type: "USER_DATA_RECIEVE", payload: state});
    return;
  }
  new FirestoreProvider().getCollectionSnapshot(`/users/${state.uuid}/alias`).then((data) => {
    const aliasArray: any = [];
    data.data.docs.forEach((element: any) => {
      aliasArray.push(element.data());
    });
    const newState = {...state, alias: aliasArray};

    dispatch({type: "USER_DATA_RECIEVE", payload: newState});
    return;
  }).catch((e) => {
    console.error(e);
    return;
  });
}

import axios from 'axios';
import {apiHost} from '../../constants/constants';
import {firestore} from '../firebase.config';

interface FirestoreProviderReply {
  data: any;
  docRef: any;
  colRef: any;
  error: boolean;
  message: string;
}

export default class FirestoreProvider {

  /**
   * Get Document Shanshot
   * 
   * Use the `reply.docRef` to start a docRef.snpshot() For
   * updating the data.
   * 
   * @param {String} path
   * @return {FirestoreProviderReply} Promise
   */
  async getDocumentSnapshot(path: string): Promise<FirestoreProviderReply> {
    return new Promise(async (resolve, reject) => {
      const docRef = firestore.doc(path);
      docRef.get().then((data) => {
        resolve({error: false, data: data, message: "SUCCESS", docRef, colRef: null});
      }).catch((error) => {
        reject({error: false, data: error, message: "ERROR_OCCURED", docRef, colRef: null});
      });
    });
  }

  /**
  * Get Collection Shanshot
  * 
  * Use the `reply.collectionRef` to start a docRef.snpshot() For
  * updating the data.
  * 
  * @param {String} path
  * @return {FirestoreProviderReply} Promise
  */
  async getCollectionSnapshot(path: string): Promise<FirestoreProviderReply> {
    return new Promise(async (resolve, reject) => {
      const collectionRef = firestore.collection(path);
      collectionRef.get().then((data) => {
        resolve({error: false, data: data, message: "SUCCESS", docRef: null, colRef: collectionRef});
      }).catch((error) => {
        reject({error: false, data: error, message: "ERROR_OCCURED", docRef: null, colRef: collectionRef});
      });
    });
  }

  /**
   * Get Collection Shanshot with Query
   * 
   * Use the `reply.collectionRef` to start a docRef.snpshot() For
   * updating the data.
   * 
   * @param {String} path
   * @return {FirestoreProviderReply} Promise
   */
  async getSearchCollectionSnapshot(path: string, where: {field: string, operator: string, value: any}): Promise<FirestoreProviderReply> {
    return new Promise(async (resolve, reject) => {
      const collectionRef = firestore.collection(path).where("staff", "==", true);
      collectionRef.get().then((data) => {
        resolve({error: false, data: data, message: "SUCCESS", docRef: null, colRef: collectionRef});
      }).catch((error) => {
        reject({error: false, data: error, message: "ERROR_OCCURED", docRef: null, colRef: collectionRef});
      });
    });
  }

  /**
  * Get Collection Shanshot with Query
  * 
  * Use the `reply.collectionRef` to start a docRef.snpshot() For
  * updating the data.
  * 
  * @param {String} path
  * @return {FirestoreProviderReply} Promise
  */
  async getSearchCollectionSnapshotWithLimit(path: string, where: {field: string, operator: any, value: any}, limit: number): Promise<FirestoreProviderReply> {
    return new Promise(async (resolve, reject) => {
      const collectionRef = firestore.collection(path).where(where.field, where.operator, where.value).limit(limit);
      collectionRef.get().then((data) => {
        resolve({error: false, data: data, message: "SUCCESS", docRef: null, colRef: collectionRef});
      }).catch((error) => {
        reject({error: false, data: error, message: "ERROR_OCCURED", docRef: null, colRef: collectionRef});
      });
    });
  }

  /**
  * Get Collection Shanshot with Query
  * 
  * Use the `reply.collectionRef` to start a docRef.snpshot() For
  * updating the data.
  * 
  * @param {String} path
  * @return {FirestoreProviderReply} Promise
  */
  async getCollectionSnapshotWithLimit(path: string, limit: number): Promise<FirestoreProviderReply> {
    return new Promise(async (resolve, reject) => {
      const collectionRef = firestore.collection(path).limit(limit);
      collectionRef.get().then((data) => {
        resolve({error: false, data: data, message: "SUCCESS", docRef: null, colRef: collectionRef});
      }).catch((error) => {
        reject({error: false, data: error, message: "ERROR_OCCURED", docRef: null, colRef: collectionRef});
      });
    });
  }

  /**
   * Checks for Alias of User.
   * If it Exists or Not.
   * @param {string} username
   * @return {Promise<boolean>}
   */
  async uuidCheck(uuidGame: string): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      const userRef = await firestore.collection('users').where('uuid', 'array-contains', uuidGame).get();

      if (userRef.size > 0) {
        resolve(true);
      }

      resolve(false);
    });
  }

  /**
   * 
   * @param {string} username
   * @return {Promise<boolean>} Promise of Type Boolean
   */
  async premiumAliasCheck(username: string): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      axios.post(`${apiHost}/minecraft/premium/user`, {username: username}).then((response) => {
        const data = response.data;

        if (data.name === undefined || data.name === null) {
          resolve(false);
        }
        resolve(true);
      });
    });
  }

  async accountRegisteredOnServerCheck(username: string): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      axios.post(`${apiHost}/server/users`, {username: username}).then((response) => {
        const data = response.data;
        resolve(data.exists)
      });
    });
  }

  async registerAccountOnServer(username: string, password: string): Promise<any> {
    return new Promise((resolve, reject) => {
      axios.post(`${apiHost}/server/register`, {username: username, password: password}).then((resoponse) => {
        const data = resoponse.data;
        console.log(data);
      });
    });
  }

  async checkOTP(otp: string): Promise<CheckOTP> {
    return new Promise((resolve, reject) => {
      firestore.collection('otp').where("token", "==", parseInt(otp)).get().then(async (data) => {
        const uuid: string = data.docs[0].get("UUID");
        const alias: string = data.docs[0].get("username");
        if (data.docs.length !== 1) {
          reject({error: true, uuid: null});
          return;
        }

        resolve({error: false, uuid: uuid, alias: alias, documentRef: data.docs[0]});
      });
    });
  }
}

interface CheckOTP {
  error: boolean,
  uuid: string,
  alias: string,
  documentRef: firebase.default.firestore.QueryDocumentSnapshot<firebase.default.firestore.DocumentData>,
}
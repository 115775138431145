import React, {Component} from "react";
import {connect} from "react-redux";
import FirebaseAuthProvider from "../services/Auth/firebase.auth";
import {auth} from "../services/firebase.config";
import {PageRouter} from "../routes/router";
import Loading from "../components/Loading/loading.component";
import {getUserAliasData, getWebsiteMainState} from "../services/state/thunk";

class FirebaseLoginController extends Component<any, any> {

  constructor(props: any) {
    super(props);
    this.props.mainState();
  }

  unsubscribeFromAuth: any = null;

  async componentDidMount() {
    this.props.loading(true);
    this.unsubscribeFromAuth = auth.onAuthStateChanged(async (userAuth) => {  

      if (userAuth) {
        const userRef = await new FirebaseAuthProvider().createUserProfileDocument(userAuth, {});
        if (userRef !== undefined) {
          userRef.onSnapshot((snapShot) => {
            this.props.userDataRecieve({loggedIn: true, ...snapShot.data()});
            this.props.loading(false);
          });
        } else {
          this.props.userDataRecieve({loggedIn: false});
          this.props.loading(false);
        }
      } else {
        this.props.userDataRecieve({loggedIn: false});
        this.props.loading(false);
      }
    });
  }

  componentWillUnmount() {
    this.unsubscribeFromAuth();
  }

  render() {
    if (this.props.main.loading) {
      return <Loading />
    }

    // TODO : Make a Maintanance Page
    if (this.props.main.maintanance) {
      return <Loading />
    }

    // return <div> ERROR </div>
    return <PageRouter />
  }
}

function mapStateToProps(state: any) {
  return {main: state.main}
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    mainState: () => getWebsiteMainState(dispatch),
    userDataRecieve: (state: any) => getUserAliasData(dispatch, state),
    loading: (state: boolean) => dispatch({type: "LOADING", payload: state}),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FirebaseLoginController);
import {Component} from 'react';
import {connect} from 'react-redux';
import {Row} from 'react-bootstrap';
import MiniDrawer from './menu/menu';
import {Redirect} from 'react-router';
import {logout} from '../../services/state/thunk';
import {Container, Typography} from '@material-ui/core';
import AccountLinking from './components/AccountLinking';
import Loading from '../../components/Loading/loading.component';
import RegisteredAccountCard from './components/RegisteredAccountCard';

interface IDashboardProp {
  logout: any
  currentUser: any
  loggedIn: boolean,
}

interface IDashboardState {
  loading: boolean,
}

class Dashboard extends Component<IDashboardProp, IDashboardState> {
  render() {
    const {currentUser} = this.props;
    if (currentUser === null) {
      return <Loading />
    }

    if (!currentUser.loggedIn) {
      return <Redirect to="/login" />
    }

    return (
      <MiniDrawer>
        <div className="text-white text-center py-2 bg-dark" >
          <Typography>
            This page will be updated in future with new features.
          </Typography>
        </div>
        <Container maxWidth="xl" className="mx-auto">
          <Row className="my-4" >
            <AccountLinking />
            <RegisteredAccountCard />
          </Row>
        </Container>
      </MiniDrawer >
    );
  }
}

function mapStateToProps(state: any) {
  return {...state.loginReducer}
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    logout: () => logout(dispatch, null),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
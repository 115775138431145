import firebase from "firebase/app";
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyAFHHtzTX8Q5fwuZz75Gbm-K9HR_7QiMOQ",
  authDomain: "dpcraftnetwork.com",
  // authDomain: "dpcraftnetwork-27f71.firebaseapp.com",
  projectId: "dpcraftnetwork-27f71",
  storageBucket: "dpcraftnetwork-27f71.appspot.com",
  messagingSenderId: "717924602692",
  appId: "1:717924602692:web:3222b151bff43edb72bd11",
  measurementId: "G-6HY25G5VYG"
};

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const storage = firebase.storage();
export const firestore = firebase.firestore();

export default firebase;
import axios from "axios";
import {skinApiHost} from "../../constants/constants";

export default class SkinApi {

  /** CheckUser */
  public static getNewSkin(identifier: string) {
    return new Promise((resolve, reject) => {
      axios.post(`${skinApiHost}skin/download`, {username: identifier}).then((response: any) => {
        if (response.data.error) resolve(false);

        resolve(true);
      });
    });
  }
}

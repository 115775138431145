import * as React from 'react';
import {createOrbitControls, SkinViewer, WalkingAnimation} from "skinview3d";
import {skinApiHost} from '../../constants/constants';
import SkinApi from '../../services/API/skin';

interface IPlayerData {
  key: string,
  player: {
    uuid: string,
    skinURL: string,
    displayName: string,
    staffMessage: string,
    rank: string,
    animate: boolean,
    control: boolean,
    alias: Array<any>,
  }
}

export default class PlayerDisplay extends React.Component<any, any> {

  constructor(props: any) {
    super(props);
    this.reloadSkin = this.reloadSkin.bind(this);
  }

  skinViewer: any = null;
  interval: any = null;

  componentDidMount() {
    const {uuid, name, animate = true, control = true} = this.props.player;

    const canvasForSkin: HTMLCanvasElement = document.getElementById(uuid) as HTMLCanvasElement;
    this.skinViewer = new SkinViewer({
      canvas: canvasForSkin,
      height: 400,
      width: 250,
      skin: `${skinApiHost}${name}.png`,
    });

    if (animate) {
      this.skinViewer.animations.add(WalkingAnimation);
    }

    if (control) {
      let control = createOrbitControls(this.skinViewer);
      control.enableRotate = true;
      control.enableZoom = false;
      control.enablePan = false;
    }

    this.reloadSkin();
  }

  reloadSkin() {
    const {name} = this.props.player;
    SkinApi.getNewSkin(name).then((data) => {
      if (data) {
        this.skinViewer.loadSkin(`${skinApiHost}${name}.png`);
        console.log('Skin Updated');
        return;
      }

      console.log('Skin Not Updated');
    });
  }

  render() {
    const {uuid, name, lastLoginTime} = this.props.player;

    const date = new Date(lastLoginTime);

    return (
      <div className="card mx-auto bg-dark" style={{minWidth: "315px", maxWidth: "395px"}}>
        {/* <div className="card-header" style={{paddingBottom: "7px", paddingTop: "7px"}}>
          <h4 className="text-center text-white" style={{marginBottom: "0px", paddingTop: "0px", paddingLeft: "0px"}}> {name} </h4>
          <h5 className="text-center text-white-50" style={{marginTop: "8px", marginBottom: "0px"}}> {rank} </h5>
        </div> */}
        <div className="card-body align-self-center">
          <div className="text-white" style={{position: "absolute", right: "7%"}} onClick={this.reloadSkin} > <i className="fa fa-2x fa-retweet" aria-hidden="true" /> </div>
          <canvas id={uuid} className="img-fluid" />
        </div >
        <div className="card-footer text-white">

          <div className="container d-flex flex-row justify-content-between"
            style={{margin: "0px", marginTop: "2px", marginBottom: "2px"}}>
            {/* <span>Discord Status</span><span><i className="fa fa-check" />&nbsp; Linked</span> */}
            <span>Name</span><span> {name} </span>
          </div>

          <div className="container d-flex flex-row justify-content-between"
            style={{margin: "0px", marginTop: "2px", marginBottom: "2px"}}>
            <span>Last Login</span>
            <span>{`${date.toLocaleDateString()} - ${date.toLocaleTimeString()}`}</span>
          </div>

        </div>
      </div >
    );
  }
}

export class StaffDisplay extends React.Component<any, any> {

  skinViewer: any = null;

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(props: any) {
    super(props);
  }

  componentDidMount() {
    const {displayName} = this.props;
    const canvasForSkin: HTMLCanvasElement = document.getElementById(displayName) as HTMLCanvasElement;
    this.skinViewer = new SkinViewer({
      canvas: canvasForSkin,
      height: 400,
      width: 250,
      skin: `${skinApiHost}/staff/${displayName}.png`,
    });

    this.skinViewer.animations.add(WalkingAnimation);
  }

  render() {
    const {displayName, rank} = this.props;

    return (
      <div className="card mx-auto bg-dark" style={{minWidth: "315px", maxWidth: "395px"}}>
        <div className="card-body align-self-center">
          <canvas id={displayName} className="img-fluid" />
        </div >

        <div className="card-footer" style={{paddingBottom: "7px", paddingTop: "7px"}}>
          <h6 className="text-center text-white" style={{marginBottom: "0px", paddingTop: "0px", paddingLeft: "0px"}}> {displayName} </h6>
          <p className="text-center text-white-50" style={{marginTop: "8px", marginBottom: "0px"}}> {rank} </p>
        </div>

      </div >
    );
  }
}

import {combineReducers} from "redux";

function staffReducer(state: any = {staffList: null}, action: any) {
  switch (action.type) {
    case "GET_STAFF":
      return {
        staffList: action.payload,
      }
    default:
      return state
  }
}

function postDataReducer(state: any = {total: 0, error: false, blogList: []}, action: {type: string, error: boolean, payload: Array<any>}) {
  switch (action.type) {
    case "INCREMENT":
      return {
        ...state,
        total: state.total + 1
      }
    case "DECREMENT":
      return {
        ...state,
        total: state.total - 1
      }
    case "GET_POST":
      return {
        ...state,
        error: action.error,
        total: action.payload.length,
        blogList: action.payload,
      }
    default:
      break;
  }
  return state
}

function loginReducer(state: any = {loading: true, loggedIn: false, currentUser: null}, action: {type: string, loggedIn: boolean, payload: any, hasError: boolean, error: any}) {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        loading: false,
        loggedIn: action.loggedIn,
        error: action.error,
        hasError: action.hasError,
        currentUser: action.payload,
      }
    case "LOGOUT":
      return {
        ...state,
        error: action.error,
        hasError: action.hasError,
        loggedIn: false,
        loading: false,
        currentUser: null,
      }
    case "REGISTER":
      return {...state}
    case "USER_DATA_RECIEVE":
      return {
        ...state, loading: false, loggedIn: action.payload.loggedIn, currentUser: action.payload,
      }
    default:
      return {...state}
  }
}

function generalReducer(state: any = {staffList: {hasData: false}, postList: {hasData: false}}, action: any) {
  switch (action.type) {
    case "GET_MAIN_STAFF":
      return {...state, staffList: {hasData: true, data: action.payload}}
    case "GET_MAIN_POST":
      return {...state, postList: {hasData: true, data: action.payload}}
    default:
      return state
  }
}

function websiteMainStateReducer(state: {loading: boolean, maintanance: boolean} = {loading: true, maintanance: false}, action: any) {
  switch (action.type) {
    case "LOADING":
      return {loading: action.payload}
    case 'MANINTANANCE':
      return {maintanance: action.payload}
    default:
      return state;
  }
}

function serverStatus(state: any = {error: false, hasData: false, data: {}}, action: any) {
  switch (action.type) {
    case "UPDATE":
      return {...action.payload};
    default:
      return state;
  }
}

export default combineReducers({
  main: websiteMainStateReducer,
  server: serverStatus,
  postDataReducer,
  loginReducer,
  staffReducer,
  generalReducer,
});
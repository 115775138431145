import {Col} from "react-bootstrap";
import {connect} from "react-redux";
import React, {Component} from "react";
import {Container} from '@material-ui/core';
import AccountLinkCard from "./AccountLinkCard";
import {InLineLoading} from "../../../components/Loading/loading.component";

class AccountLinking extends Component<any, any> {
  render() {
    const {alias} = this.props;

    if (alias === undefined) {
      <InLineLoading />
    }

    if (alias.length < 2) {
      return (
        <Col sm="12" md="7" className="mx-auto" >
          <Container maxWidth="lg">
            <div className="text-white" >
              {/* Account Linking */}
              <div>
                {/* <NewAccountCard /> */}
                <span className="mt-3" >
                  <AccountLinkCard />
                </span>
              </div>
            </div >
          </Container>
        </Col>
      )
    }

    return <div />
  }
}

function mapStateToProps(state: any) {
  return {alias: state.loginReducer.currentUser.alias}
}

export default connect(mapStateToProps)(AccountLinking);
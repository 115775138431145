import React, {Component} from "react";
import {Col} from "react-bootstrap";
import {connect} from "react-redux";
import PlayerDisplay from "../../../components/playerModel/model";
import {InLineLoading} from "../../../components/Loading/loading.component";

class RegisteredAccounts extends Component<any, any> {
  render() {
    const {alias} = this.props;

    if (alias === undefined) {
      return <InLineLoading />
    }

    if (alias.length > 0) {
      return alias.map((e: any) => {
        return (
          <Col key={e.uuid} className="mt-5" >
            <PlayerDisplay key={e.uuid} player={e} />
          </Col>
        );
      })
    }

    return <div />
  }
}

function mapStateToProps(state: any) {
  return {alias: state.loginReducer.currentUser.alias}
}

export default connect(mapStateToProps)(RegisteredAccounts);
import * as React from "react";
import {connect} from "react-redux";
import {Nav, Navbar} from "react-bootstrap";
import {Link} from "react-router-dom";
import {logout} from "../../services/state/thunk";
import Loading, {InLineLoading} from "../Loading/loading.component";
const navLinks = [
  {title: `HOME`, path: `/`},
  // {title: `UPDATES`, path: `/updates`},
  {title: `STORE`, path: `/store`},
  // {title: `SUPPORT`, path: `/support`},
];

class NavigationBar extends React.Component<any, any> {
  render() {
    const {logout} = this.props;
    console.log(this.props);
    return (
      <div>
        <Navbar variant="dark" className="text-left" bg="transparent" expand="lg" >
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              {
                navLinks.map((data) => {
                  return <Nav.Link key={data.title} className="mx-0 mx-md-4 my-2 px-2 text-white" as={Link} to={data.path} >{data.title}</Nav.Link>
                })
              }
            </Nav>
            <Nav className="ml-auto">
              {
                this.props.loading === true ? <InLineLoading /> :
                this.props.loggedIn ? (
                  <>
                    <Nav.Link key="dashboard" className="mx-0 mx-md-4 my-2 px-2 text-white border-white" as={Link} to="/dashboard" > DASHBOARD </Nav.Link>
                    <Nav.Link key="login" className="mx-0 mx-md-4 my-2 px-2 text-white" onClick={logout} > LOGOUT </Nav.Link>
                  </>
                ) : <Nav.Link key="login" className="mx-0 mx-md-4 my-2 px-2 text-white" as={Link} to="/login" > LOGIN </Nav.Link>
              }
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  return {...state.loginReducer}
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    logout: () => logout(dispatch, null),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavigationBar)
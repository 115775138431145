import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Col, OverlayTrigger, Row} from 'react-bootstrap';

interface IPingServer {
  hasData: boolean,
  error: boolean,
  serverStatus: {
    duration: number,
    last_online: number,
    last_updated: number,
    motd: string,
    online: boolean,
    players: {
      max: number,
      now: number,
    }
    server: {
      name: string,
      status: string,
    }
    status: string,
  }
}

class PingServer extends Component<any, IPingServer> {
  render() {
    const {hasData, serverStatus, error} = this.props.server;
    if (serverStatus === undefined) {
      return <div id="serverIp" className="text-success bg-dark py-2 text-center"> Pinging Server . . . </div>
    }
  
    const playerCount: string = `${serverStatus.online ? "text-success" : "text-danger"} p-3`;
  
    if (error || serverStatus.status === "error") {
      return <div id="serverIp" className="text-danger bg-dark py-2 text-center"> Server Ping Error </div>
    }
  
    if (hasData) {
      return (
        <div id="serverIp" className="text-white bg-dark py-2">
          <Row className="text-center" >
            <Col className="col-12 col-sm-auto ml-auto" >
              <OverlayTrigger placement="bottom" delay={{show: 250, hide: 250}} overlay={<div id="button-tooltip" className="bg-transparent text-white p-3 rounded"> Click to Copy ! </div>}>
                <span> play.dpcraftnetwork.com </span>
              </OverlayTrigger>
            </Col>
            <Col className="col-12 col-sm-auto" >
              <OverlayTrigger placement="bottom" delay={{show: 250, hide: 250}} overlay={<div id="button-tooltip" className="bg-transparent text-white p-3 rounded">  Server is {serverStatus.online ? "Online" : "Offline"} </div>}>
                <span>
                  | <span className={playerCount} > {serverStatus.online ? "Online" : "Offline"} </span> |
                </span>
              </OverlayTrigger>
            </Col>
            <Col className="col-12 col-sm-auto mr-auto" >
              <OverlayTrigger placement="bottom" delay={{show: 250, hide: 250}} overlay={<div id="button-tooltip" className="bg-transparent text-white p-4 rounded"> {serverStatus.players.now} Player Online. Are you one of them ? </div>}>
                <span> {serverStatus.players.now} / {serverStatus.players.max} </span>
              </OverlayTrigger>
            </Col>
          </Row>
        </div>
      );
    }
  
    return <div />
  }
}

function mapStateToProps(state: any) {
  return {server: state.server}
}

export default connect(mapStateToProps)(PingServer);
import * as React from 'react';
import {Card, Container, Drawer, Typography} from '@material-ui/core';
import {Component} from 'react';
import {Col, Image, Row} from 'react-bootstrap';
import {connect} from 'react-redux';
import NavigationBar from '../../components/navbar/navbar';
import {fetchBlogFromApi} from '../../services/state/thunk';
import store from '../../services/state/store';
import {v4 as uuid} from 'uuid';
import moment from 'moment';
import ReactMarkdown from 'react-markdown';
import './style.scss';
const gfm = require('remark-gfm');

class Blog extends Component<any, any> {

  constructor(props: any) {
    super(props);
    store.dispatch(fetchBlogFromApi);
  }

  render() {
    const {blogList} = this.props;
    return (
      <Container maxWidth="xl" >

        <NavigationBar />

        <Typography className="text-white text-center mt-5" variant="h5" > Recent Update's </Typography>

        <div className="text-white text-center mt-4" >

          <Row>
            {
              blogList.length > 0 ? (
                blogList.map((element: any) => {
                  const data = element.data();
                  return <UpdateCard key={data.title + uuid()} {...data} />
                })) : <div />
            }
          </Row>
          <div className="w-100 bg-primary mt-5 p-3" />
        </div>
      </Container >
    );
  }
}

function mapStateToProps(state: any) {
  return state.postDataReducer
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getBlog: () => dispatch({type: 'GET_BLOG'}),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Blog)

export function UpdateCard(props: any) {
  const [open, toggleDrawer] = React.useState(false);
  const {title, image, content, timestamp} = props;
  return (
    <Col xs="12" lg="4" className="mt-3">
      <Card className="bg-dark blogCard mx-auto" onClick={() => toggleDrawer(true)}>
        <Image src={image} className="img-fluid p-2 img-noclick" draggable="false" onContextMenu={(e) => e.preventDefault()} />
        <Typography variant="h6" className="p-2" > {title} </Typography>
        <ReactMarkdown remarkPlugins={[gfm]} className="p-2 ellipsis mb-3" >{content}</ReactMarkdown>
        <div className="card-footer mt-auto">
          <small className="text-muted">Posted on {moment.unix(timestamp).format("DD MMM YYYY")}</small>
        </div>
      </Card>
      <UpdateDispaly state={open} data={props} toggleDrawer={toggleDrawer} />
    </Col>
  );
}

function UpdateDispaly(props: any) {
  const {state, data, toggleDrawer} = props;
  const {image, title, content, timestamp} = data;

  return <Drawer PaperProps={{className: "bg-primary h-100"}} anchor='bottom' open={state} onClose={() => toggleDrawer(false)}>
    <Container maxWidth="lg">
      <Card className="text-left bg-dark px-lg-5 my-lg-5 my-0">
        <div className="d-flex flex-row justify-content-between mt-3">
          <Typography variant="h5">{title}</Typography>
          <div className="text-right pointer" onClick={() => toggleDrawer(false)} ><i className="fa fa-times fa-lg" />&nbsp;</div>
        </div>
        <Typography variant="body1" className="text-muted pb-2" > Posted on {moment.unix(timestamp).format("DD MMM YYYY")} </Typography>
        <Container className="py-4 px-2 text-center"  >
          <Image width="700" src={image} className="img-fluid my-4 py-4 py-md-0 text-center" />
          <ReactMarkdown remarkPlugins={[gfm]} className="px-0 px-lg-3 pb-4 text-justified">{content}</ReactMarkdown>
        </Container>
      </Card>
    </Container>
  </Drawer >
}
import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import MaterialUILink from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import NavigationBar from '../../components/navbar/navbar';
import { login, register } from '../../services/state/thunk';

class SignUp extends React.Component<any, any> {

  constructor(props: any) {
    super(props);
    this.state = {
      firstName: null,
      lastName: null,
      email: null,
      password: null,
      cnfPassword: null,
      recieveUpdates: false,
    }
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event: any) {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  }

  render() {
    if (this.props.currentUser != null) {
      if (this.props.currentUser.loggedIn) 
      return <Redirect to={"/dashboard"} />
    }
    
    return (
      <div className="bg-primary" style={{ height: "100vh" }}>
        <NavigationBar />

        <Typography className="text-white text-center mt-5 mb-2" component="h1" variant="h5"> Sign Up </Typography>
        <Typography className="text-white text-center text-muted" component="h1" variant="body2"> Create your Account to begin your Adventure </Typography>
        <Container component="main" maxWidth="sm" className="py-5 my-5 bg-dark text-white rounded" >
          <div>
            <form noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="firstName"
                    autoComplete="fname"
                    variant="outlined"
                    required
                    fullWidth
                    id="firstName"
                    label="First Name"
                    autoFocus
                    onChange={this.handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="lastName"
                    variant="outlined"
                    required
                    fullWidth
                    id="lastName"
                    label="Last Name"
                    autoComplete="lname"
                    onChange={this.handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="email"
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    autoComplete="email"
                    onChange={this.handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    onChange={this.handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="cnfPassword"
                    label="Confirm Password"
                    type="password"
                    id="cnfPassword"
                    autoComplete="current-password"
                    onChange={this.handleChange}
                  />
                </Grid>
                <Grid item xs={12} className="my-3" >
                  <FormControlLabel
                    control={<Checkbox name="recieveUpdates" value={this.state.recieveUpdates} color="primary" onChange={() => this.setState({ recieveUpdates: this.state.recieveUpdates ? false : true })} />}
                    label="I want to receive promotions and updates via email."
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className="my-3"
                onClick={(event) => { event.preventDefault(); this.props.register(this.state); }}
              >
                Sign Up
          </Button>
              <div className="text-center" >
                <Typography variant="body2" className="text-muted my-2" > Sign In Directly With </Typography>

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className="my-3"
                  onClick={(event) => { event.preventDefault(); this.props.googleLogin() }}
                > Google Sign In </Button>

                <MaterialUILink component={Link} to="/login" variant="body2">
                  Already have an account? Sign in
              </MaterialUILink>
              </div>
            </form>
          </div>
        </Container>
      </div >
    );
  }
}

function mapStateToProps(state: any) {
  return { ...state.loginReducer }
}


const mapDispatchToProps = (dispatch: any) => {
  return {
    register: (state: any) => register(dispatch, state),
    googleLogin: () => login(dispatch, { type: "googleLogin" }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
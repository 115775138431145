import * as React from "react";
import "./style.scss";
import {connect} from "react-redux";
import {Container, Typography} from "@material-ui/core";
import store from "../../services/state/store";
import NavigationBar from "../../components/navbar/navbar";
import Footer from "../../components/footer/footer.component";
import {fetchLandingPageData} from "../../services/state/thunk";

import About from "./sections/About";
import Updates from "./sections/Updates";
import LetGoSocial from "./sections/Social";
import PingServer from "./sections/PingServer";
import {Row} from "react-bootstrap";
import PlayerDisplay from "../../components/playerModel/model";
import StaffList from "./sections/StaffList";

interface IAppProp {
  postList: {hasData: boolean, data: any}
  staffList: {hasData: boolean, data: any}
  server: any,
}

class App extends React.Component<IAppProp, any> {

  constructor(props: any) {
    super(props);
    this.state = {
      hasData: false,
      error: false,
      serverStatus: {},
      successCopy: false,
    }
    store.dispatch(fetchLandingPageData);
    this.handleClose = this.handleClose.bind(this);
    this.copyServerLinkToClipboard = this.copyServerLinkToClipboard.bind(this);
  }

  handleClose(event?: React.SyntheticEvent, reason?: string) {
    // if (reason === 'clickaway') {
    //   return;
    // }
    this.setState({successCopy: false});
  }

  copyServerLinkToClipboard() {
    const el = document.createElement('textarea');
    el.value = "play.dpcraftnetwork.com";
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    //TODO: Show Snack Bar, URL Copied to Clipboard
  }

  render() {   
    return (
      <div>
        <Container maxWidth="xl">
          
          {/* Navigation Bar */}
          <NavigationBar />

          {/* Server Status */}
          <div onClick={this.copyServerLinkToClipboard} >
            <PingServer />
          </div>

          {/* What is DP Craft Network's */}
          <About />

          {/* Updates */}
          {/* <Updates /> */}

          {/* Meet the Team Section */}
          <StaffList />

          {/* Lets Get Social */}
          <div className="pt-5" >
            <LetGoSocial />
          </div>

          {/* Footer */}
          <Footer />
        </Container>
      </div >
    );
  }
}

export default connect()(App)


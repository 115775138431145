import React from 'react';
import {Link} from 'react-router-dom';
import {Button, Col, Image, Row} from "react-bootstrap";

export default function LetGoSocial() {
  return (
    <div className="text-center text-white px-4 px-sm-5 mt-5 mb-5" >
      <Row className="mx-auto p-0 p-sm-5" >
        <Col className="col-12 col-lg-6 mx-auto text-right" >
          <Image className="img-fluid" width="500" src={window.location.origin + "/assets/panda.png"} />
        </Col>
        <Col className="col-12 col-lg-6 mx-auto text-left mt-5 mt-sm-0" >
          <h1 className="text-center text-sm-left" > Let's get social </h1>
          <p className="mt-4 text-justify" >
            We think communication is the key to an amazing community. <br />
          Because of that, we heavily value our Discord and the way <br />
          it allows us to connect with our favourite people ever - you!
        </p>
          <Link target={"_blank"} to="/discord" > <Button className="px-5 py-2 mt-4 btn-info" > Join Our Discord </Button> </Link>
        </Col>
      </Row>
    </div>
  );
}
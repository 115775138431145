import React from 'react';
import {Container, Typography} from '@material-ui/core';

export default function About() {
  return (
    <div className="text-center text-white py-5" >
    <Typography variant="h3" className="mt-5 mb-3" > About </Typography>
    <Container maxWidth="md">
      <hr style={{height: "2px"}} className="bg-info w-25" />
      <Typography className="pt-3" >
        DPCraft Network is a Cracked Minecraft server founded by @theDaddyxP, and it was released on January 10th, 2020. Ever since our server's player base has been growing with players not only from India but also users from our neighbouring countries (such as Bangladesh, Nepal and Sri Lanka) playing our fantastic gamemodes and enjoying their time here with our beloved community.
      </Typography>
    </Container>
  </div>
  );
}
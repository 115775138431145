import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MaterialUILink from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import NavigationBar from '../../components/navbar/navbar';
import {login} from '../../services/state/thunk';
import {Redirect} from "react-router-dom";
import Loading from '../../components/Loading/loading.component';

class SignIn extends React.Component<any, any> {

  constructor(props: any) {
    super(props);
    this.state = {
      email: "",
      password: "",
    }
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event: any) {
    const {value, name} = event.target;
    this.setState({[name]: value});
  }

  render() {    
    if (this.props.currentUser != null) {
      if (this.props.currentUser.loggedIn) 
      return <Redirect to={"/dashboard"} />
    }

    return (
      <div className="bg-primary" style={{height: "100vh"}}>
        <NavigationBar />

        <Typography className="text-white text-center mt-5 mb-2" component="h1" variant="h5"> Sign in </Typography>
        <Typography className="text-white text-center text-muted" component="h1" variant="body2"> Login to Recieve Support or Customize your Experience </Typography>
        <Container maxWidth="sm" className="py-5 my-5 bg-dark text-white rounded">
          <div className="text-center" >
            <form>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={this.handleChange}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={this.handleChange}
              />
              {/* <div className="text-left my-2" >
                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Remember me"
                />
              </div> */}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className="my-2"
                onClick={(event) => {event.preventDefault(); this.props.login(this.state);}}
              > Sign In </Button>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className="my-2"
                onClick={(event) => {event.preventDefault(); this.props.googleLogin()}}
              > Sign In with Google </Button>
              {/* <div className="my-3" >
                <MaterialUILink component={Link} to="/forgotpassword" variant="body2"> Forgot password? </MaterialUILink>
              </div> */}
              <MaterialUILink component={Link} to="/register" variant="body2"> {"Don't have an account? Sign Up"} </MaterialUILink>
            </form>
          </div>
        </Container >
      </div >
    );
  }
}

function mapStateToProps(state: any) {
  return {...state.loginReducer}
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    login: (state: any) => login(dispatch, {type: "email", data: {...state}}),
    googleLogin: () => login(dispatch, {type: "googleLogin"}),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
import {Container} from '@material-ui/core';
import * as React from 'react';
import {Component} from 'react';
import NavigationBar from "../../components/navbar/navbar";

class Support extends Component<any, any> {
  render() {
    return (
      <Container maxWidth="xl">
        <NavigationBar />
      </Container>
    );
  }
}

export default Support;
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Row} from 'react-bootstrap';
import {Typography} from '@material-ui/core';
import PlayerDisplay, {StaffDisplay} from '../../../components/playerModel/model';
import {InLineLoading} from '../../../components/Loading/loading.component';

class StaffCarousel extends Component<any, any> {
  render() {
    const {staffList} = this.props;

    if (staffList === null) {
      return <InLineLoading />
    }

    if (staffList.length > 0) {

      return (
        <div className="text-center text-white my-5 p-3" >
        <Typography variant="h4" className="my-5" > Meet The Team </Typography>
        <Row>
          {
            staffList.map((element: any) => {
              const playerData = element.data();
                return <StaffDisplay key={element.id} {...playerData} />
            })
          }
        </Row>
      </div>
      );
    }

    return <div />
  }
}

function mapStateToProps(state: any) {
  return {...state.staffReducer}
}

export default connect(mapStateToProps)(StaffCarousel);
import * as React from 'react';
import {Component} from 'react';
import {connect} from 'react-redux';
import FirebaseLoginController from './login.state.controller';

const status = require('minecraft-server-status-improved');

class ServerStatusController extends Component<any, any> {

  constructor(props: any) {
    super(props);
    this.loadServerStatus = this.loadServerStatus.bind(this);
  }

  interval: any = null;
  skinReload: any = null;

  componentDidMount() {
    this.loadServerStatus();

    const self: this = this;

    // Get Latest Server Status
    this.interval = setInterval(() => {
      self.loadServerStatus();
    }, 3000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    clearInterval(this.skinReload)
  }

  loadServerStatus() {
    status('play.dpcraftnetwork.com')
      .then((data: object) => {
        this.props.serverDataRecieve({hasData: true, error: false, serverStatus: data});
      }).catch((error: any) => {
        console.error(error);
        this.props.serverDataRecieve({hasData: false, error: true, serverStatus: {}});
      });
  }

  render() {
    return (
      <FirebaseLoginController />
    );
  }
}

function mapStateToProps(state: any) {
  return {...state}
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    serverDataRecieve: (state: any) => dispatch({type: "UPDATE", payload: state}),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ServerStatusController);